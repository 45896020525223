import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "bergamont" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "bergamont-elcyklar---upptäck-friheten-på-två-hjul"
    }}>{`Bergamont Elcyklar - Upptäck Friheten på Två Hjul`}</h1>
    <p>{`Välkommen till vår Bergamont brand-page! Här hittar du ett brett sortiment av högkvalitativa elektriska cyklar från Bergamont, en ledande tillverkare inom cykelindustrin som är känd för sina innovativa och robusta lösningar för både stad och terräng. Utforska de olika serierna inom Bergamont elcyklar och hitta den perfekta modellen för just dina behov.`}</p>
    <h2 {...{
      "id": "översikt-över-bergamont-elcykel-serien"
    }}>{`Översikt över Bergamont Elcykel Serien`}</h2>
    <p>{`Bergamont erbjuder flera serier av elcyklar, var och en skräddarsydd för specifika behov och användningsområden. Här är en översikt av de främsta serierna:`}</p>
    <h3 {...{
      "id": "bergamont-e-vitess-series"
    }}>{`Bergamont E-Vitess Series`}</h3>
    <p><strong parentName="p">{`Bergamont E-Vitess`}</strong>{` är den perfekta hybriden för stadspendling och utflykter. Dessa elcyklar kombinerar hög prestanda med exceptionell komfort och stil. Med kraftfulla TQ-HPR50 motorer och Shimano Nexus växelsystem, erbjuder de smidiga och pålitliga växlingar. `}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`TQ-HPR50 mittmotor`}</li>
      <li parentName="ul">{`Integrerat 360 Wh batteri`}</li>
      <li parentName="ul">{`Shimano Nexus 5-växlad navväxel`}</li>
      <li parentName="ul">{`Ergonomisk design`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar`}</li>
    </ul>
    <h3 {...{
      "id": "bergamont-e-horizon-series"
    }}>{`Bergamont E-Horizon Series`}</h3>
    <p><strong parentName="p">{`Bergamont E-Horizon`}</strong>{` är en utmärkt serie för stadscyklister som söker både stil och funktionalitet. Dessa modeller är försedda med effektiva Bosch-motorer och robusta batterier som ger lång räckvidd perfekt för daglig pendling och längre turer. `}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Bosch Active Line Plus mittmotor`}</li>
      <li parentName="ul">{`Bosch PowerTube 500 Wh batteri`}</li>
      <li parentName="ul">{`Shimano Nexus växelsystem`}</li>
      <li parentName="ul">{`Ergonomisk sadel med dämpning`}</li>
      <li parentName="ul">{`Effektiva hydrauliska skivbromsar`}</li>
    </ul>
    <h3 {...{
      "id": "bergamont-e-revox-series"
    }}>{`Bergamont E-Revox Series`}</h3>
    <p><strong parentName="p">{`Bergamont E-Revox`}</strong>{` är serien för den äventyrliga själen. Dessa elcyklar är byggda för att klara både stadsmiljö och tuffare terräng. Med kraftfulla Bosch Performance Line CX motorer och robusta 29-tums däck, är de redo för alla utmaningar.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Bosch Performance Line CX mittmotor`}</li>
      <li parentName="ul">{`Bosch 625 Wh batteri`}</li>
      <li parentName="ul">{`Shimano Deore växelsystem med 11 växlar`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar från Shimano`}</li>
      <li parentName="ul">{`Intuitiv Bosch display för enkel övervakning`}</li>
    </ul>
    <h3 {...{
      "id": "bergamont-e-grandurance-series"
    }}>{`Bergamont E-Grandurance Series`}</h3>
    <p><strong parentName="p">{`Bergamont E-Grandurance`}</strong>{` erbjuder den ultimata kombinationen av prestanda och komfort. Dessa cyklar är perfekt anpassade för både grusvägar och stadsasfalt. Med TQ-HPR50 motor och lätt SCM-Carbon ram ger de en smidig körupplevelse oavsett underlag.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`TQ-HPR50 mittmotor`}</li>
      <li parentName="ul">{`360 Wh batteri`}</li>
      <li parentName="ul">{`Shimano GRX växelsystem`}</li>
      <li parentName="ul">{`Kolfiberdämpning och hållbar design`}</li>
      <li parentName="ul">{`Högkvalitativa skivbromsar`}</li>
    </ul>
    <h2 {...{
      "id": "köpguiden---vilken-bergamont-elcykel-passar-dig"
    }}>{`Köpguiden - Vilken Bergamont Elcykel Passar Dig?`}</h2>
    <p>{`Att välja rätt elcykel handlar om att matcha cykelns funktioner med dina behov. Här är några avgörande faktorer att tänka på när du väljer Bland och bland modellserierna:`}</p>
    <h3 {...{
      "id": "användningsområde"
    }}>{`Användningsområde`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stadspendling`}</strong>{`: Bergamont E-Vitess och E-Horizon serierna är optimala för stadsmiljö med sina ergonomiska designegenskaper och lång räckvidd.`}</li>
      <li parentName="ul"><strong parentName="li">{`Off-road äventyr`}</strong>{`: Välj Bergamont E-Revox om du planerar att cykla i terräng och behöver en robust cykel som klarar ojämna underlag.`}</li>
    </ul>
    <h3 {...{
      "id": "komfort-och-ergonomi"
    }}>{`Komfort och Ergonomi`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ergonomisk Färd`}</strong>{`: För en bekväm pendling rekommenderar vi modeller från E-Vitess och E-Grandurance serierna. Dessa erbjuder fantastiska komfortfunktioner som ergonomisk design och högkvalitativa sadlar.`}</li>
    </ul>
    <h3 {...{
      "id": "tekniska-funktioner"
    }}>{`Tekniska Funktioner`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor och Batteri`}</strong>{`: Tänk på hur lång räckvidd du behöver. De stora Bosch-batterierna och mittmotorerna i E-Horizon och E-Revox serierna är idealiska för längre turer.`}</li>
      <li parentName="ul"><strong parentName="li">{`Växelsystem`}</strong>{`: För smidiga och pålitliga växlingar, erbjuder E-Vitess och E-Horizon serierna Shimano Nexus-växelsystem, medan E-Revox och E-Grandurance har mer avancerade växelsystem för tuffare cykling.`}</li>
    </ul>
    <p>{`Genom att förstå dina cykelbehov och matcha dem med rätt serie, finner du enkelt din ideala elektriska cykel från Bergamont.`}</p>
    <h2 {...{
      "id": "kontakta-oss"
    }}>{`Kontakta oss`}</h2>
    <p>{`För ytterligare hjälp med att välja den perfekta Bergamont elcykeln eller om du har några frågor, tveka inte att kontakta oss. Vi finns här för att hjälpa dig att hitta din perfekta cykelpartner!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      